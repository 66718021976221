const Reducer = (state, action) => {
	switch (action.type) {
		case "SET_PRODUCT_IMG":
			return {
				...state,
				productImg: action.payload,
			}

		case "SET_RECEIPT_REFERENCE":
			console.log(action)

			return {
				...state,
				receiptReference: action.receiptReference,
				receiptFileName: action.receiptFileName,
			}

		case "SET_ORDER_ENTRY":
			return {
				...state,
				[action.key]: action.value,
			}

		default:
			return state
	}
}

export default Reducer
